<template>
  <div class="van-main project-detail">
    <van-tabs v-model="active">
      <van-tab title="项目信息">
        <div class="area-title">
          <p class="title">
            立项信息
          </p>
        </div>
        <div class="van-common-detail">
          <van-cell-group>
            <van-cell title="项目名称" :value="utils.isEffectiveCommon(projectDetail.projectName)" />
            <van-cell title="项目编号" :value="utils.isEffectiveCommon(projectDetail.projectNo)" />
            <van-cell title="产品类型" :value="utils.statusFormat(String(projectDetail.productType), 'ProjectProductType')" />
          </van-cell-group>
          <van-cell-group>
            <van-cell title="委托客户" :value="utils.isEffectiveCommon(projectDetail.dsEnterpriseName)" />
            <van-cell title="统一社会信用代码" :value="utils.isEffectiveCommon(projectDetail.dsEnterpriseOrgNo)" />
            <van-cell v-if="projectDetail.dsFirst" title="" value="首次合作" />
          </van-cell-group>
          <van-cell-group>
            <van-cell title="贸易执行企业" :value="utils.isEffectiveCommon(projectDetail.tradeOrgName)" />
            <van-cell title="资金方" :value="utils.isEffectiveCommon(projectDetail.tenantName)" />
            <van-cell title="业务部经理" :value="utils.isEffectiveCommon(projectDetail.mangerName)" />
          </van-cell-group>
        </div>
      </van-tab>
      <van-tab title="产品模式">
        <van-cell title="产品类型" :value="utils.statusFormat(String(projectDetail.productType), 'ProjectProductType')" />
        <van-cell-group v-for="productMode in productModeList" :key="productMode.projectNo" :title="'支付节点：' + productMode.payNode">
          <van-cell title="支付比例（%）：" :value="utils.isEffectiveCommon(productMode.payScale)" />
          <van-cell title="支付要件：" value="" />
          <van-cell v-for="(item, index) in productMode.projectProductModeTempDocsVoList" :key="productMode.payNodeNo + index" :title="(index + 1) + '.' + utils.isEffectiveCommon(item.payDocsName)" />
          <van-cell title="备注：" :value="utils.isEffectiveCommon(productMode.remarks)" />
        </van-cell-group>
      </van-tab>
      <van-tab title="交易方案">
        <div class="area-title">
          <p class="title">
            货物信息
          </p>
        </div>
        <div class="van-common-detail">
          <van-cell-group>
            <van-cell title="一级分类" :value="utils.isEffectiveCommon(projectDetail.firstCategoryName)" />
            <van-cell title="备注" :value="utils.isEffectiveCommon(projectDetail.remarks)" />
          </van-cell-group>
        </div>
        <div class="area-title">
          <p class="title">
            垫资信息
          </p>
        </div>
        <div class="van-common-detail">
          <van-cell-group>
            <van-cell title="定金比例（%）" :value="utils.isEffectiveCommon(fundsForm.marginLevel)" />
            <van-cell title="预期追加定金" :value="`每低于市场价格 ${utils.isEffectiveCommon(fundsForm.lessThrid)}% 时，追加定金比例${utils.isEffectiveCommon(fundsForm.marginCall)}`" />
            <van-cell title="结算周期（天）" :value="utils.isEffectiveCommon(fundsForm.settlementCycle)" />
            <van-cell title="计费方式" :value="utils.statusFormat(String(fundsForm.billingMethod),'ProjectBillingMethod')" />
            <van-cell title="最低起息天数" :value="utils.isEffectiveCommon(fundsForm.minValueDays)" />
            <van-cell title="逾期费率（%）" :value="utils.isEffectiveCommon(fundsForm.overdueRate)" />
            <van-cell title="资金服务费" value="阶梯费用" />
          </van-cell-group>
        </div>
        <div class="table">
          <div class="th">
            <div class="fl">
              公历年度累计柜数
            </div>
            <div class="fl">
              执行年费率
            </div>
            <div class="clearfix" />
          </div>
          <div v-for="(platformRate) in fundsForm.projectPurchaseSaleInfoVoList" :key="platformRate.id" class="tr">
            <div class="fl">
              {{ platformRate.range }}
            </div>
            <div class="fl">
              {{ utils.isEffectiveCommon(platformRate.rate) }}
            </div>
            <div class="clearfix" />
            <van-divider />
          </div>
        </div>
        <van-cell title="收费标准" :value="utils.isEffectiveCommon(fundsForm.platformServiceTypeRate)" />
        <!-- <van-cell title="代理费用类型" :value="utils.statusFormat(fundsForm.platformServiceType,'ProjectPlatformServiceType')" />
        <template v-if="Number(fundsForm.platformServiceType) === 2">
          <van-cell title="打包报关费(元/吨）" :value="utils.moneyFormat(fundsForm.packingDeclarationCharge)" />
          <van-cell title="最低打包吨数（吨）" :value="utils.moneyFormat(fundsForm.minPackingWeight)" />
          <van-cell title="供应链管理服务费(元/吨）" :value="utils.moneyFormat(fundsForm.supplyManageCharge)" />
        </template> -->
        <template v-if="Number(fundsForm.platformServiceType) === 3">
          <van-cell title="平台手续费" :value="utils.moneyFormat(fundsForm.platformServiceTypeRate)" />
        </template>
      </van-tab>
      <van-tab title="合同信息">
        <div class="contract-list">
          <van-list
            v-model="contractLoading"
            :finished="contractFinished"
            finished-text="没有更多了"
            @load="getContractList"
          >
            <div v-for="item in contractList" :key="item.sealId" class="contract-item">
              <div>
                <div class="fl">
                  <p><span>合同名称</span><span>{{ item.contractName }}</span></p>
                  <p><span>合同编号</span> <span>{{ item.contractNumber }}</span></p>
                </div>
                <div class="fl">
                  <van-button plain round size="small" type="info" native-type="button" @click="contractDetail(item)">
                    查看详情
                  </van-button>
                </div>
                <div class="clearfix" />
              </div>
              <van-divider />
            </div>
          </van-list>
        </div>
      </van-tab>
      <van-tab title="相关附件">
        <attachment-list module-name="PROJECT" :business-id="projectDetail.projectId" />
      </van-tab>
      <van-tab title="审核意见">
        <workflow-audit v-if="Number($route.query.taskViewMode)!==0" page-key="project_apply" :business-key="projectDetail.projectId" :proc-inst-id="$route.query.procInstId" :variable="{businessId:projectDetail.projectId ,motif:projectDetail.projectName}" :view-mode="true" @confirmAudit="utils.backReturn($route.query.messageType)" />
        <workflow-audit v-else page-key="project_apply" :business-key="projectDetail.projectId" :task-id="$route.query.taskId" :variable="{businessId:projectDetail.projectId ,motif:projectDetail.projectName}" @confirmAudit="utils.backReturn($route.query.messageType)" />
      </van-tab>
    </van-tabs>
    <back-button />
  </div>
</template>

<script>
import { Dialog, Cell, CellGroup, Search, List, Divider, Button, Tab, Tabs, Toast } from 'vant'
import AttachmentList from '@/components/attachment-list'
import WorkflowAudit from '@/components/workflow-audit'
import BackButton from '@/components/back-button'
export default {
  components: {
    BackButton,
    AttachmentList,
    WorkflowAudit,
    [Dialog.Component.name]: Dialog.Component,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Search.name]: Search,
    [List.name]: List,
    [Button.name]: Button,
    [Divider.name]: Divider,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Toast.name]: Toast
  },
  data () {
    return {
      id: this.$route.query.id,
      active: 0,
      projectDetail: {
        firstCategoryName: ''
      },
      productModeList: [],
      fundsForm: {
        // 保证金（定金）比例
        marginLevel: '',
        lessThrid: '',
        marginCall: '',
        settlementCycle: '',
        billingMethod: '',
        minValueDays: '',
        funderRateList: [],
        platformRateList: [],
        projectPurchaseSaleInfoVoList: [],
        platformServiceTypeRate: ''
      },
      contractLoading: false,
      contractFinished: false,
      contractList: []
    }
  },
  created () {
    this.getDetail()
  },
  methods: {
    getDetail () {
      Toast.loading({ duration: 0, forbidClick: true })
      let api = this.api.project.detail
      if (this.utils.checkSystemType(this.constants.systemType.nongtouSystem)) {
        api = this.api.project.detailNongtou
      }
      api({ projectId: this.id }).then(result => {
        this.projectDetail = result.data.value
        this.getProductModeDetail().then(result => {
          result.forEach(item => {
            item.projectProductModeTempDocsVoList = item.payDocsList.filter(o => o.checked === 1)
          })
          this.productModeList = result
        }).catch(() => {
          this.getProductModeTemplate().then(result => {
            this.productModeList = result
          })
        })
        this.getCategoryList().then(result => {
          if (result) {
            let classFyId = this.projectDetail.cargoClassifyParentId
            if (!classFyId) {
              classFyId = this.projectDetail.cargoClassifyId
            }
            const data = this._.find(result, { cargoClassifyId: classFyId }) || {}
            this.projectDetail.firstCategoryName = data.classifyName
          }
        }).catch(() => {
        })
        this.getFundsDetail().then(result => {
          const fundsForm = result
          if (!fundsForm.projectPurchaseSaleInfoVoList || fundsForm.projectPurchaseSaleInfoVoList.length === 0) {
            fundsForm.projectPurchaseSaleInfoVoList = [
              { startDay: 1, endDay: undefined, serviceType: 0, rate: undefined },
              { startDay: null, endDay: undefined, serviceType: 0, rate: undefined }
            ]
          }
          fundsForm.projectPurchaseSaleInfoVoList.forEach((item, index) => {
            if (index !== fundsForm.projectPurchaseSaleInfoVoList.length - 1) {
              fundsForm.projectPurchaseSaleInfoVoList[index].range = this.startDayFormat(fundsForm.projectPurchaseSaleInfoVoList, index) + '~' + item.endDay
            } else {
              fundsForm.projectPurchaseSaleInfoVoList[index].range = this.startDayFormat(fundsForm.projectPurchaseSaleInfoVoList, index) + '以上'
            }
          })
          this.fundsForm = fundsForm
        }).catch(() => {
        })
      }).finally(() => {
        Toast.clear()
      })
    },
    getProductModeDetail () {
      return new Promise((resolve, reject) => {
        this.api.project.productModeDetail({ projectId: this.projectDetail.projectId }).then(result => {
          if (result.data.value) {
            resolve(result.data.value)
          } else {
            reject(result.data.value)
          }
        }).catch(e => {
          reject(e)
        }).finally(() => {
        })
      })
    },
    getProductModeTemplate () {
      return new Promise((resolve, reject) => {
        this.api.project.productModeTemple().then(res => {
          const list = this._.filter(res.data.value, { productMode: this.projectDetail.productType })
          resolve(list)
        }).catch(e => {
          reject(e)
        }).finally(() => {
        })
      })
    },
    // 获取分类
    getCategoryList () {
      return new Promise((resolve, reject) => {
        this.api.cargoManagement.categoryList({ cargoParentId: 0 }).then(result => {
          resolve(result.data.value)
        }).catch(e => {
          reject(e)
        })
      })
    },
    // 获取垫资信息
    getFundsDetail () {
      return new Promise((resolve, reject) => {
        this.api.project.fundsInfo({ projectId: this.projectDetail.projectId }).then(result => {
          resolve(result.data.value)
        }).catch(e => {
          reject(e)
        })
      })
    },
    // 开始日期格式化 会改动数据
    startDayFormat (list, index) {
      const beforeData = list[index - 1]
      if (beforeData) {
        list[index].startDay = beforeData.endDay ? beforeData.endDay + 1 : undefined
      } else {
        list[index].startDay = 1
      }
      return this.utils.isEffectiveCommon(list[index].startDay)
    },
    getContractList () {
      this.api.contract.listPage({ pageSize: 100, projectId: this.projectDetail.projectId }).then(res => {
        this.contractList = res.data.value.list || []
        this.contractFinished = true
      }).finally(() => {
      })
    },
    contractDetail (row) {
      this.$router.push({ name: 'contractDetail', query: { id: row.sealId, procInstId: row.processId, taskViewMode: 1 } })
    }
  }
}
</script>

<style lang="less" scoped>
.project-detail {
.van-tabs {
    margin-bottom: 60px;
}
.contract-list {
  .contract-item {
    font-size: 14px;
    margin-top: 20px;
    >div>div:first-child {
      width: 70%;
      p {
        margin-left: 20px;
        >span {
          display: inline-block;
          height: 16px;
          line-height: 16px;
          vertical-align: middle;
        }
        >span:nth-child(2) {
          margin-left: 15px;
          overflow: hidden;
          width: 150px;
        }
      }
    }
    >div>div:nth-child(2) {
      width: 30%;
      text-align: center;
    }
    .van-divider {
      margin-top: 5px;
    }

  }
}
.table {
  font-size: 14px;
  margin: auto 10px;
  padding-bottom: 10px;
  .th {
    background-color: rgb(242, 242, 242);
    border: 1px solid rgb(215, 215, 215);
    border-radius: 5px 5px 0px 0px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    font-weight: 700;
    >div:first-child {
      width: 50%;
    }
    >div:nth-child(2) {
      width: 50%;
    }
  }
  .tr {
    height: 50px;
    text-align: center;
    line-height: 50px;
    font-weight: 400;
    >div:first-child {
      width: 50%;
    }
    >div:nth-child(2) {
      width: 50%;
    }
  }
  .van-divider {
    margin-top: 0px;
  }
}
}
</style>
