var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main project-detail" },
    [
      _c(
        "van-tabs",
        {
          model: {
            value: _vm.active,
            callback: function($$v) {
              _vm.active = $$v
            },
            expression: "active"
          }
        },
        [
          _c("van-tab", { attrs: { title: "项目信息" } }, [
            _c("div", { staticClass: "area-title" }, [
              _c("p", { staticClass: "title" }, [_vm._v(" 立项信息 ")])
            ]),
            _c(
              "div",
              { staticClass: "van-common-detail" },
              [
                _c(
                  "van-cell-group",
                  [
                    _c("van-cell", {
                      attrs: {
                        title: "项目名称",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.projectDetail.projectName
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "项目编号",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.projectDetail.projectNo
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "产品类型",
                        value: _vm.utils.statusFormat(
                          String(_vm.projectDetail.productType),
                          "ProjectProductType"
                        )
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "van-cell-group",
                  [
                    _c("van-cell", {
                      attrs: {
                        title: "委托客户",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.projectDetail.dsEnterpriseName
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "统一社会信用代码",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.projectDetail.dsEnterpriseOrgNo
                        )
                      }
                    }),
                    _vm.projectDetail.dsFirst
                      ? _c("van-cell", {
                          attrs: { title: "", value: "首次合作" }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "van-cell-group",
                  [
                    _c("van-cell", {
                      attrs: {
                        title: "贸易执行企业",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.projectDetail.tradeOrgName
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "资金方",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.projectDetail.tenantName
                        )
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "业务部经理",
                        value: _vm.utils.isEffectiveCommon(
                          _vm.projectDetail.mangerName
                        )
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "van-tab",
            { attrs: { title: "产品模式" } },
            [
              _c("van-cell", {
                attrs: {
                  title: "产品类型",
                  value: _vm.utils.statusFormat(
                    String(_vm.projectDetail.productType),
                    "ProjectProductType"
                  )
                }
              }),
              _vm._l(_vm.productModeList, function(productMode) {
                return _c(
                  "van-cell-group",
                  {
                    key: productMode.projectNo,
                    attrs: { title: "支付节点：" + productMode.payNode }
                  },
                  [
                    _c("van-cell", {
                      attrs: {
                        title: "支付比例（%）：",
                        value: _vm.utils.isEffectiveCommon(productMode.payScale)
                      }
                    }),
                    _c("van-cell", {
                      attrs: { title: "支付要件：", value: "" }
                    }),
                    _vm._l(
                      productMode.projectProductModeTempDocsVoList,
                      function(item, index) {
                        return _c("van-cell", {
                          key: productMode.payNodeNo + index,
                          attrs: {
                            title:
                              index +
                              1 +
                              "." +
                              _vm.utils.isEffectiveCommon(item.payDocsName)
                          }
                        })
                      }
                    ),
                    _c("van-cell", {
                      attrs: {
                        title: "备注：",
                        value: _vm.utils.isEffectiveCommon(productMode.remarks)
                      }
                    })
                  ],
                  2
                )
              })
            ],
            2
          ),
          _c(
            "van-tab",
            { attrs: { title: "交易方案" } },
            [
              _c("div", { staticClass: "area-title" }, [
                _c("p", { staticClass: "title" }, [_vm._v(" 货物信息 ")])
              ]),
              _c(
                "div",
                { staticClass: "van-common-detail" },
                [
                  _c(
                    "van-cell-group",
                    [
                      _c("van-cell", {
                        attrs: {
                          title: "一级分类",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.projectDetail.firstCategoryName
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "备注",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.projectDetail.remarks
                          )
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "area-title" }, [
                _c("p", { staticClass: "title" }, [_vm._v(" 垫资信息 ")])
              ]),
              _c(
                "div",
                { staticClass: "van-common-detail" },
                [
                  _c(
                    "van-cell-group",
                    [
                      _c("van-cell", {
                        attrs: {
                          title: "定金比例（%）",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.fundsForm.marginLevel
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "预期追加定金",
                          value:
                            "每低于市场价格 " +
                            _vm.utils.isEffectiveCommon(
                              _vm.fundsForm.lessThrid
                            ) +
                            "% 时，追加定金比例" +
                            _vm.utils.isEffectiveCommon(
                              _vm.fundsForm.marginCall
                            )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "结算周期（天）",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.fundsForm.settlementCycle
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "计费方式",
                          value: _vm.utils.statusFormat(
                            String(_vm.fundsForm.billingMethod),
                            "ProjectBillingMethod"
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "最低起息天数",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.fundsForm.minValueDays
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "逾期费率（%）",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.fundsForm.overdueRate
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: { title: "资金服务费", value: "阶梯费用" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "table" },
                [
                  _c("div", { staticClass: "th" }, [
                    _c("div", { staticClass: "fl" }, [
                      _vm._v(" 公历年度累计柜数 ")
                    ]),
                    _c("div", { staticClass: "fl" }, [_vm._v(" 执行年费率 ")]),
                    _c("div", { staticClass: "clearfix" })
                  ]),
                  _vm._l(_vm.fundsForm.projectPurchaseSaleInfoVoList, function(
                    platformRate
                  ) {
                    return _c(
                      "div",
                      { key: platformRate.id, staticClass: "tr" },
                      [
                        _c("div", { staticClass: "fl" }, [
                          _vm._v(" " + _vm._s(platformRate.range) + " ")
                        ]),
                        _c("div", { staticClass: "fl" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.utils.isEffectiveCommon(platformRate.rate)
                              ) +
                              " "
                          )
                        ]),
                        _c("div", { staticClass: "clearfix" }),
                        _c("van-divider")
                      ],
                      1
                    )
                  })
                ],
                2
              ),
              _c("van-cell", {
                attrs: {
                  title: "收费标准",
                  value: _vm.utils.isEffectiveCommon(
                    _vm.fundsForm.platformServiceTypeRate
                  )
                }
              }),
              Number(_vm.fundsForm.platformServiceType) === 3
                ? [
                    _c("van-cell", {
                      attrs: {
                        title: "平台手续费",
                        value: _vm.utils.moneyFormat(
                          _vm.fundsForm.platformServiceTypeRate
                        )
                      }
                    })
                  ]
                : _vm._e()
            ],
            2
          ),
          _c("van-tab", { attrs: { title: "合同信息" } }, [
            _c(
              "div",
              { staticClass: "contract-list" },
              [
                _c(
                  "van-list",
                  {
                    attrs: {
                      finished: _vm.contractFinished,
                      "finished-text": "没有更多了"
                    },
                    on: { load: _vm.getContractList },
                    model: {
                      value: _vm.contractLoading,
                      callback: function($$v) {
                        _vm.contractLoading = $$v
                      },
                      expression: "contractLoading"
                    }
                  },
                  _vm._l(_vm.contractList, function(item) {
                    return _c(
                      "div",
                      { key: item.sealId, staticClass: "contract-item" },
                      [
                        _c("div", [
                          _c("div", { staticClass: "fl" }, [
                            _c("p", [
                              _c("span", [_vm._v("合同名称")]),
                              _c("span", [_vm._v(_vm._s(item.contractName))])
                            ]),
                            _c("p", [
                              _c("span", [_vm._v("合同编号")]),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(item.contractNumber))])
                            ])
                          ]),
                          _c(
                            "div",
                            { staticClass: "fl" },
                            [
                              _c(
                                "van-button",
                                {
                                  attrs: {
                                    plain: "",
                                    round: "",
                                    size: "small",
                                    type: "info",
                                    "native-type": "button"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.contractDetail(item)
                                    }
                                  }
                                },
                                [_vm._v(" 查看详情 ")]
                              )
                            ],
                            1
                          ),
                          _c("div", { staticClass: "clearfix" })
                        ]),
                        _c("van-divider")
                      ],
                      1
                    )
                  }),
                  0
                )
              ],
              1
            )
          ]),
          _c(
            "van-tab",
            { attrs: { title: "相关附件" } },
            [
              _c("attachment-list", {
                attrs: {
                  "module-name": "PROJECT",
                  "business-id": _vm.projectDetail.projectId
                }
              })
            ],
            1
          ),
          _c(
            "van-tab",
            { attrs: { title: "审核意见" } },
            [
              Number(_vm.$route.query.taskViewMode) !== 0
                ? _c("workflow-audit", {
                    attrs: {
                      "page-key": "project_apply",
                      "business-key": _vm.projectDetail.projectId,
                      "proc-inst-id": _vm.$route.query.procInstId,
                      variable: {
                        businessId: _vm.projectDetail.projectId,
                        motif: _vm.projectDetail.projectName
                      },
                      "view-mode": true
                    },
                    on: {
                      confirmAudit: function($event) {
                        return _vm.utils.backReturn(
                          _vm.$route.query.messageType
                        )
                      }
                    }
                  })
                : _c("workflow-audit", {
                    attrs: {
                      "page-key": "project_apply",
                      "business-key": _vm.projectDetail.projectId,
                      "task-id": _vm.$route.query.taskId,
                      variable: {
                        businessId: _vm.projectDetail.projectId,
                        motif: _vm.projectDetail.projectName
                      }
                    },
                    on: {
                      confirmAudit: function($event) {
                        return _vm.utils.backReturn(
                          _vm.$route.query.messageType
                        )
                      }
                    }
                  })
            ],
            1
          )
        ],
        1
      ),
      _c("back-button")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }